import { initAnalytics, trackPage } from "common/analytics"
import { initApplicationInsights } from "common/applictionInsights"
import { fetchShowRooms } from "features/showRooms/showRoomsSlice"
import Layout from "layout/Layout"
import {
  fetchLogoAlt,
  fetchMenus,
  setPurchaseModelId,
} from "layout/layoutSlice"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import { Router, useRouter } from "next/router"
import Script from "next/script"
import { useEffect } from "react"
import { useAppDispatch, wrapper } from "store"
import styled from "styled-components"
import theme from "theme/theme"
import ThemeProviders from "theme/ThemeProviders"

const ORIGIN = process.env.ORIGIN
const PURCHASE_MODEL_ID = process.env.PURCHASE_MODEL_ID
if (!ORIGIN) {
  throw new Error("Please provide the ORIGIN environment variable.")
}

const ENV = process.env.ENV

Router.events.on("routeChangeComplete", (url: string) => {
  // let react router do the page tracking for the model page
  if (/^\/\d+/.test(url)) return
  trackPage()
  window.appInsights?.trackPageView()
})

const StyledEnvironmentName = styled.pre`
  background-color: ${theme.colors.darkSlateBlue};
  color: ${theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  margin: 0;
  opacity: 0.8;
  pointer-events: none;
`

const MyApp = ({ Component, pageProps }: AppProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)

    // Initialize Azure Application Insights

    if (window) {
      window.addEventListener("load", () => {
        initAnalytics()
        trackPage()
        initApplicationInsights()
      })
    }
  }, [])

  useEffect(() => {
    dispatch(setPurchaseModelId(PURCHASE_MODEL_ID))
    dispatch(fetchShowRooms())
    dispatch(fetchLogoAlt())
    dispatch(fetchMenus())
  }, [dispatch])

  const { asPath } = useRouter()
  const url = (ORIGIN + asPath).split(/[?#]/)[0] // remove query string & hash

  return (
    <>
      <DefaultSeo
        canonical={url}
        openGraph={{
          site_name: "ג'ילי | Geely",
          locale: "he_IL",
          type: "website",
          url,
          images: [
            {
              url: "https://geely.co.il/saving_image_calculator_mobile_NEW.jpg",
              width: 1080,
              height: 675,
            },
            {
              url: "https://geely.co.il/saving_image_calculator_mobile_NEW.jpg",
              width: 256,
              height: 165,
            },
          ],
        }}
        additionalMetaTags={[
          { name: "theme-color", content: theme.colors.lightBlue },
        ]}
      />
      <ThemeProviders>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProviders>

      {ENV && ENV !== "production" && (
        <StyledEnvironmentName>{ENV}</StyledEnvironmentName>
      )}
      {!url.includes("/showroom") ? (
        <>
          <Script
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
        var widgetOptions = {
          apiKey: "d01597c0-4287-467a-b044-63ac5a6fa3b8",
          snippetId: "HBMamBAXVdkbPLXRgIws",
          position:"left",
          mobilePosition:"left",
          mobileLauncherSize:"small",
          bottom:"70px",
          // launcherIcon:"/chatBotLogo.svg",
           launcherColor:"#000",
           disableIntroductionMessage:true,
           disableIntroductionMessageOnMobile:true
     };

     (function(n){var u=function(){GlassixWidgetClient&&typeof GlassixWidgetClient=="function"?(window.widgetClient=new GlassixWidgetClient(n),widgetClient.attach(),window.glassixWidgetScriptLoaded&&window.glassixWidgetScriptLoaded()):f()},f=function(){r.onload=u;r.src="http://webdefence.global.blackspider.com/urlwrap/?q=AXicDcpBCgIhFADQ7xHadojvOEwRraIWBUHUJmhpKqPx1dDPlMfqaN2g3vrNdvBZCfjOBRRq3eKOtUwYdSCTE5dMaHKEXh1OZ3-c1HLoBgVbzd4lvGmr22bvHLX_wkDgmZ91LaWxCUfStYY3JsfSUHCJq3wFOzpGhT3GkPBRAeB6EfADUBoq2g&Z";document.body&&document.body.removeChild(t);i.parentNode.insertBefore(r,i)},i=document.getElementsByTagName("script")[0],t=document.createElement("script"),r;(t.async=!0,t.type="text/javascript",t.crossorigin="anonymous",t.id="glassix-widget-script",r=t.cloneNode(),t.onload=u,t.src="https://cdn.glassix.com/clients/widget.1.2.min.js",!document.getElementById(t.id)&&document.body)&&(i.parentNode.insertBefore(t,i),t.onerror=f)})(widgetOptions)
     `,
            }}
          />
          <Script
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                ttq.load('CMT79UBC77U1TB56JODG');
                ttq.page();
              }(window, document, 'ttq');
              `,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default wrapper.withRedux(MyApp)
